const MAX_UINT32_VALUE = 4294967295; // 2 ** 32 - 1

export default (max?: number) => {
  const value =
    crypto.getRandomValues(new Uint32Array(1))[0] / MAX_UINT32_VALUE;

  if (!max || max === 1) return value;

  return Math.ceil(value * max);
};
